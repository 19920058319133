import { extractErrorMessageOrDefault } from "utils";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";
import { useState } from "react";

type SetMsg = React.Dispatch<React.SetStateAction<string | null>>;

type UseMsgResult = [string | null, SetMsg, () => void];

/**
 * Internal helper to manage message state.
 * Returns a triple with the message value, setter, and clear function.
 */
const useMsg = (): UseMsgResult => {
  const [msg, setMsg] = useState<string | null>(null);
  const clear = () => setMsg(null);

  return [msg, setMsg, clear];
};

export type UsePageFeedbackResult = {
  setSuccessMsg: SetMsg;
  successProps: {
    successMessage: string | null;
    onDismiss: () => void;
  };
  clearAndSetSuccess: (msg: string) => void;
  setInfoMsg: SetMsg;
  infoProps: {
    infoMessage: string | null;
    onDismiss: () => void;
  };
  clearAndSetInfo: (msg: string) => void;
  setErrorMsg: SetMsg;
  errorProps: {
    errorMessage: string | null;
    onDismiss: () => void;
  };
  clearAndSetError: (msg: string) => void;
  handleErrorWithMessage: (err: unknown) => void;
  clearAll: () => void;
  hasError: boolean;
  hasSuccess: boolean;
  hasInfo: boolean;
};

/**
 * Manages state for success, info, and error messages - typically for a top level page/route.
 *
 * @example
 *
 * const { successProps, errorProps, infoProps ...feedback } = usePageFeedback();
 *
 * // in your components markup
 *
 * <Error {...errorProps} />
 * <Success {...successProps} />
 * <Info {...infoProps} />
 *
 * <button onClick={feedback.clearAndSetSuccess("Saved!")}>Save</button>
 */
const usePageFeedback = (): UsePageFeedbackResult => {
  const [successMessage, setSuccessMsg, clearSuccessMsg] = useMsg();
  const [infoMessage, setInfoMsg, clearInfoMsg] = useMsg();
  const [errorMessage, setErrorMsg, clearErrorMsg] = useMsg();

  const hasError = !isNullOrUndefOrEmpty(errorMessage);
  const hasInfo = !isNullOrUndefOrEmpty(infoMessage);
  const hasSuccess = !isNullOrUndefOrEmpty(successMessage);

  const clearAll = () => [clearSuccessMsg, clearInfoMsg, clearErrorMsg].forEach((setMsg) => setMsg());

  return {
    setSuccessMsg,
    successProps: {
      successMessage,
      onDismiss: clearSuccessMsg,
    },
    clearAndSetSuccess(message) {
      clearAll();
      setSuccessMsg(message);
    },
    setInfoMsg,
    infoProps: {
      infoMessage,
      onDismiss: clearInfoMsg,
    },
    clearAndSetInfo(message) {
      clearAll();
      setInfoMsg(message);
    },
    setErrorMsg,
    errorProps: {
      errorMessage,
      onDismiss: clearErrorMsg,
    },
    clearAndSetError(message) {
      clearAll();
      setErrorMsg(message);
    },
    /**
     * Convenience function to use as a handler for events that provide an error.
     *
     * @example
     *
     * {
     *    onError: feedback.handleErrorWithMessage,
     * }
     */
    handleErrorWithMessage(err) {
      clearAll();
      setErrorMsg(extractErrorMessageOrDefault(err));
    },
    /**
     * Convenience function that clears all messages
     */
    clearAll,
    hasError,
    hasInfo,
    hasSuccess,
  };
};

export default usePageFeedback;
